import * as React from 'react';
import { Box, Divider, Grid, Hidden } from '@material-ui/core';
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import * as Mock from '@visx/mock-data';
import clsx from 'clsx';
import { Link } from 'gatsby';

import { IconButton, SEO, Typography } from '../../../../../atoms';
import { ColorLabel, DateSelector, Pagination } from '../../../../../molecules';
import {
  CustomerJourneyChart,
  UserBehaviourAnalyticsChart,
  UserDataPieChart,
} from '../../../../../organisms';
import { GenericPage, Layout } from '../../../../../layout';

import useStyles from './AnalyticsUsersPage.styles';

const customerJourneyMockData = [
  {
    category: 'View',
    value: 100,
  },
  {
    category: 'Interaction',
    value: 55,
  },
  {
    category: 'Conversion',
    value: 28,
  },
];
const userActivityMockData = [
  { category: 'Call', value: 10 },
  { category: 'Email', value: 15 },
  { category: 'Move to another listing', value: 10 },
  { category: 'Read further content', value: 20 },
  { category: 'Contact platform for support', value: 10 },
];
const sectionActivityMockData = [
  { category: 'General media', value: 10 },
  { category: 'Property details', value: 15 },
  { category: 'Single property media', value: 10 },
  { category: 'Facilities & services', value: 20 },
  { category: 'Map & nearby places', value: 10 },
  { category: 'Virtual Tours', value: 30 },
  { category: 'Area guide', value: 50 },
];
const points1 = Mock.genDateValue(4, 0, Date.now());
const points2 = Mock.genDateValue(4, 0.3, Date.now());
const individualUserMockData = [
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
  },
];

export default function AnalyticsUsersPage() {
  const classes = useStyles();
  const [datesFrom, setDatesFrom] = React.useState<[Date, Date] | [Date]>([
    new Date(2022, 2, 2),
  ]);
  const [datesTo, setDatesTo] = React.useState<
    [Date, Date] | [Date] | undefined
  >([new Date(2022, 2, 3)]);

  return (
    <Layout>
      <SEO title="Analytics Users" />
      <GenericPage>
        <Box className={classes.ctn}>
          <Box className={classes.contentCtn}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <ColorLabel className={classes.title}>
                      Customer journey
                    </ColorLabel>
                    <Box
                      className={clsx(
                        classes.chartCtn,
                        classes.customerJourneyChart
                      )}
                    >
                      <CustomerJourneyChart data={customerJourneyMockData} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ColorLabel className={classes.title}>
                      User behavior analytics
                    </ColorLabel>
                    <Box
                      className={clsx(
                        classes.chartCtn,
                        classes.userBehaviorChartCtn
                      )}
                    >
                      <Box mb={1.5}>
                        <DateSelector
                          datesFrom={datesFrom}
                          datesTo={datesTo}
                          setDatesFrom={setDatesFrom}
                          setDatesTo={setDatesTo}
                        />
                      </Box>
                      <Divider />
                      <Box
                        my={1.5}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box display="flex">
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                          >
                            <ArrowLeftIcon />
                          </IconButton>
                          <Box>
                            <Box className={classes.iconButtonText}>
                              Conversion
                            </Box>
                          </Box>
                          <IconButton
                            className={classes.iconButton}
                            size="small"
                          >
                            <ArrowRightIcon />
                          </IconButton>
                        </Box>
                        <Box textAlign="right">
                          <Typography
                            color="primary"
                            fontSize={20}
                            fontWeight="bold"
                          >
                            35%
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <ArrowDropUpIcon
                              className={classes.up}
                              fontSize="small"
                              height={15}
                            />
                            <Typography
                              className={classes.up}
                              fontSize={12}
                              fontWeight={600}
                            >
                              3.24%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={classes.userBehaviorChart}>
                        <UserBehaviourAnalyticsChart
                          data={[points1, points2]}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <ColorLabel className={classes.title}>
                      User activity
                    </ColorLabel>
                    <Box className={classes.chartCtn} height={270}>
                      <Box height={130}>
                        <UserDataPieChart data={userActivityMockData} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ColorLabel className={classes.title}>
                      Section behaviour
                    </ColorLabel>
                    <Box className={classes.chartCtn} height={270}>
                      <Box height={130}>
                        <UserDataPieChart data={sectionActivityMockData} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ColorLabel className={classes.title}>
                      Individual user listing
                    </ColorLabel>
                    <Box className={classes.card}>
                      <table
                        className={classes.userTable}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          {individualUserMockData.map((user, index) => (
                            <tr key={index}>
                              <td>
                                <Box
                                  bgcolor="#B9CFFD"
                                  borderRadius={8}
                                  fontSize={13}
                                  fontWeight={600}
                                  mr={3}
                                  px={1.5}
                                  py={0.5}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {user.status}
                                </Box>
                              </td>
                              <td>
                                <Box
                                  fontSize={13}
                                  fontWeight={600}
                                  borderRadius="50%"
                                  bgcolor="white"
                                  mr={1.5}
                                  width={25}
                                  height={25}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {user.userType}
                                </Box>
                              </td>
                              <td>
                                <Link className={classes.userLink} to="">
                                  {user.user}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Hidden mdUp>
            <Pagination showDescription={false} total={50} />
          </Hidden>
        </Box>
      </GenericPage>
    </Layout>
  );
}
