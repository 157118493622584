import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#F8F8F8',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        padding: theme.spacing(3, 2),
      },
    },
    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(3, 2),
      maxWidth: theme.custom.container1460,
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    title: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      marginBottom: theme.spacing(2.5),
    },
    chartCtn: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3, 1.5),
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    },
    iconButton: {
      width: '1.5rem',
      height: '1.25rem',
    },
    iconButtonText: {
      fontSize: '0.875rem',
      fontWeight: 600,
      color: theme.palette.gray.contrastText,
      lineHeight: '1.25rem',
    },
    up: {
      color: '#2CA792',
    },
    down: {
      color: theme.palette.primary.main,
    },
    userLink: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    userTable: {
      border: 'none',
      borderSpacing: theme.spacing(0, 3),
    },
    card: {
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(1),
        backgroundColor: 'white',
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 6px rgba(0,0,0,0.05)',
        padding: theme.spacing(2),
      },
    },
    customerJourneyChart: {
      height: 250,
      [theme.breakpoints.up('md')]: {
        height: 500,
      },
    },
    userBehaviorChartCtn: {
      height: 450,
      [theme.breakpoints.up('md')]: {
        height: 600,
      },
    },
    userBehaviorChart: {
      height: 250,
      [theme.breakpoints.up('md')]: {
        height: 400,
      },
    },
  })
);

export default useStyles;
